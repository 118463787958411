section.profile {
    margin-bottom: 50px;
}

section.profile > div {
    display: grid;
    gap: 30px;
}

section.profile > div img {
    width: 100%;
}

section.profile > div > div {
    display: grid;
    grid-template-columns: 100%;
}

section.profile > div div ul {
        text-align: right;
    }


@media screen and (min-width: 577px) {
    section.profile > div {
        grid-template-columns: fit-content(378px) auto;
    }
}