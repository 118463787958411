.features {
    gap: 30px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: end;
    border: 1px solid var(--border-color);
    flex: 30%;
}
 
@media screen and (max-width: 736px) {
    .feature {
        flex: 80%;
    }
}

.feature div {
    width: 100%;
    padding: 20px;
    display: grid;
    align-items: end;
    height: 100%;
    grid-template-rows: repeat(2, min-content) auto;
}

.feature div *:not(:first-child) {
    font-size: 0.9em;
}

.feature div > a:last-child {
    width: max-content;
    justify-self: end;
}
.feature h4 {
    text-align: center;
    width: 100%;
}

.feature a:first-child, .feature h4 a {
    border: none;
    width: 100%;
}

.feature h4 a:first-child:hover, .feature h4 a:hover {
    border-bottom: dotted !important;
    border-width: 1px !important;
}

.feature a img {
    width: 100%;
}

.feature p {
    width: 0;
    min-width: 100%;
    margin: 0;
}

.download-grid {
    display: grid;
    row-gap: 50px;
    column-gap: 50px;
    grid-template-columns: repeat(auto-fill, 220px);
}

.download-grid a {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 30px 10px;
    text-align: center;
    height: 200px;
}

.download-grid a span {
    padding-bottom: 20px;
}

.file-icon {
    width: 50px;
}

a:hover { 
    border-bottom-color: var(--border-color) !important;
}