
/* Header */
:root {
    --menu-bg-color: #2e3842;
  }

#header {
    -moz-transition: background-color 0.2s ease;
    -webkit-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    background: var(--menu-bg-color);
    line-height: 3em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

    @media screen and (max-width: 736px) {        
        #header nav > a {
            display: inline-block !important;
        }
    }

        #header h1 a {
            border: 0;
            height: inherit;
            line-height: inherit;
            font-size: 0.8em;
        }

    #header nav {
        height: inherit;
        line-height: inherit;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        position: relative;
        left: 0;
    }
    
    @media screen and (max-width: 736px) {
        #header nav {
            height: 44px;
        }
    }

        #header nav a {
            font-size: 0.8em;
            letter-spacing: 0.225em;
            text-transform: uppercase;
            border: 0;
            color: #fff;
            text-decoration: none;
        }

            #header nav a:hover, #header nav a.active {
                border-bottom: dotted !important;
                border-width: 1px !important;
            }

        #header nav ul {
            list-style: none;
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
            
            #header nav ul.menu li {
                list-style: none;
                padding: 0 15px;
                position: relative;
            }

                #header nav ul.menu li#close {
                    display: none;
                    background-image: url(../../assets/css/images/close.svg);
                    background-position: 4.85em 1em;
                    background-repeat: no-repeat;
                    border: 0;
                    cursor: pointer;
                    height: 3em;
                    position: absolute;
                    right: 0;
                    top: 0;
                    vertical-align: middle;
                    width: 7em;
                }

                    #header nav ul.menu li#close span {
                        display: none;
                    }

                header nav ul.menu li.dropdown > ul {
                display: none;
                position: absolute;
                padding: 0;
                margin: 0;
                background-color: var(--menu-bg-color);
                width: 100%;
                left: 0;
                width: auto;
                }
        
                    header nav ul.menu li.dropdown:hover > ul {
                        display: block;
                    }
                    
                    header nav ul.menu li.dropdown > ul li {
                        min-width: 140px;
                    }
 
            @media screen and (max-width: 736px) {
                header nav ul.menu {
                    display: none;
                    grid-auto-rows: max-content;
                    position: absolute;
                    right: 0;
                    background-color: var(--menu-bg-color);
                    padding: 45px 15px 15px 15px !important;
                    height: 100vh;
                }

                header nav ul.menu.show {
                    display: grid !important;
                }

                header nav ul.menu li.dropdown > ul {
                    display: inline;
                    position: relative;
                }

                #header nav ul.menu li#close {
                    display: inline;
                }
            }

        #header nav > ul > li {
            display: inline-block;
        }

        #header nav > a.menuToggle {
            position: absolute;
            right: 1.25em;
            display: none;
            border: none !important;
        }

            #header nav a.menuToggle:after {
                background-image: url("../../assets/css/images/bars.svg");
                background-position: right center;
                background-repeat: no-repeat;
                content: '';
                display: inline-block;
                height: 3.75em;
                vertical-align: top;
                width: 2em;
            }

                #header nav > a.menuToggle span {
                    display: none;
                }

#devbuild {
    width: 100%;
    height: 32px; 
    color: white;
    background-color: var(--signal);
    text-align: center;
}